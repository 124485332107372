import React from 'react';
import Button from '../Button';

const Subscribe = () =>
  <div className='subscribe'>
    <div className='container'>
      <p className='info'>Want to get the most updated investment advice?</p>
      <div className='input input--shadow'>
        <input placeholder='Enter Your Email' type='text'/>
      </div>
      <Button text={'Subscribe'} size={'lg'} color={'navy'} shadow/>
    </div>
  </div>;

export default Subscribe;
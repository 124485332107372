import React from 'react';
import PredictionBox from './PredictionBox.js'
import PredictionChart from './PredictionChart'
import './LandingPage.css'
import QuestionsSection from '../../reactBasic/components/basics/QuestionsSection'
import ProductSection from '../../reactBasic/components/basics/ProductInfo/ProductSection'
import {answers, questions, routes} from "./_data";

const LandingPage = () =>
	<div className={'margin_center pic1'}>
			<div className={'upper_Container'}>
				<div className={'paddingContainer'}>
					<p className={'MainHeading'}>
						Index Prediction Challenge
					</p>
					<p className={'DescriptionOfGame'}>
						Guess the HSI and S&P closing price before the market start,
						<br/>
						or modify your prediction at Intra-day to get better result.
						<br/>
						We will pick the most accurate user every 2 weeks.
						<br/>
						Reward is suspended and will be resumed until further notice.
					</p>
					<PredictionBox/>
				</div>
			</div>
			<br/>
			<PredictionChart/>
			<QuestionsSection questions={questions} answers={answers} route={'/how-to-play/#HowToPlayMenuHead'}
			                  routes={routes}/>
			<ProductSection/>
		</div>;


export default LandingPage;
import React, {useContext, useEffect, useState} from 'react';
import {API, API_data, chart_actual_color, chart_intra_color, chart_pre_color} from '../../utilities/constantsFile';
import {TabButton, TabButtonItem} from "../../reactBasic/components/basics/Tab";
import classNames from 'classnames';
import {columns, col_diff, col_score, options, button} from "./table_data";
import Button from 'react-bootstrap/Button';
import UserContext from "../../Context"
import GraphContainer from "../../reactBasic/components/modules/Graph/predictionSite/GraphContainer";
import QuestionsSection from "../../reactBasic/components/basics/QuestionsSection";
import {answers, questions, routes} from "../LandingPage/_data";
import './MyBoard.css'
import HistoryTable from "../PredictionHistory/HistoryTable";
import Spinner from '../component/Spinner';
import avatar from '../assets/img/avatar.svg'

const updateTableData = (data) => {
	data.forEach(datum => {
			datum['date_time'] = datum['date_time'].slice(0, 10);
			datum['submit_date'] = datum['submit_date'].slice(11,);
		}
	);
	return data
};

const updatePersonalTableData = (data) => {
	data.forEach(datum => {
			datum['round_start_date'] = datum['round_start_date'].slice(5,);
			datum['round_end_date'] = datum['round_end_date'].slice(4,);
			datum['period'] = datum['round_start_date'] + datum['round_end_date']
		}
	);
	return data
};

const MyBoard = () => {
	const {userData} = useContext(UserContext);
	const [user_data, setUser_data] = useState();
	const [diff_user_data, set_diff_user_data] = useState();
	const [score_user_data, set_score_user_data] = useState();
	const [actual_consensus_data, setActual_consensus_data] = useState();
	const [user_data_chart, setUser_data_chart] = useState();
	const [indexSelected, setIndexSelected] = useState('hsi');
	const [show_all, setShow_all] = useState(false);
	const [avatar_url, setAvatar_url] = useState(null);
	const [table_size, set_table_size] = useState(5);
	
	let user_name, uuid;
	if (userData) {
		user_name = userData.display_name;
		uuid = userData.uuid;
	}
	
	useEffect(() => {
		if (userData) {
			Promise.all([get_user_profile_photo(), get_actual_consensus_chart_series(), get_user_chart_series()]).then()
		}
	}, [userData]);
	
	useEffect(() => {
		if (userData) {
			get_personal_table_data(indexSelected).then();
		}
	}, [userData, indexSelected]);
	
	const get_actual_consensus_chart_series = async () => {
		const [consensus_data, hsi_actual, sp_actual] = await Promise.all([
			fetch(API + '/web/v1/index-prediction/actual_consensus?return_type=chart'),
			fetch(API_data + '/stock/index/hsi/price?return_type=chart&start_date=2019-01-01'),
			fetch(API_data + '/stock/index/gspc/price?return_type=chart&start_date=2019-01-01')
		]);
		let series = await consensus_data.json();
		series.hsi.actual = await hsi_actual.json();
		series.sp.actual = await sp_actual.json();
		setActual_consensus_data(series);
	};
	
	const get_user_chart_series = async () => {
		let [user_data, user_data_chart] = await Promise.all([
			fetch(API + `/web/v1/index-prediction/user_name/${user_name}`),
			fetch(API + `/web/v1/index-prediction/user_name/${user_name}?return_type=chart`)
		]);
		setUser_data(await user_data.json());
		setUser_data_chart(await user_data_chart.json());
	};
	
	const get_user_profile_photo = () => {
		fetch(API + `/web/v1/users?display_name=${user_name}`)
		.then(r => r.json())
		.then(j => {
			if (j.profile_photo) setAvatar_url(j.profile_photo);
			else setAvatar_url(null);
		})
	};
	
	const get_personal_table_data = async (index) => {
		const index_data = await fetch(API + `/web/v1/index-prediction/user_record/${uuid}?index=${index}`);
		let index_data_json = await index_data.json();
		set_diff_user_data(await updatePersonalTableData(index_data_json.diff));
		set_score_user_data(await updatePersonalTableData(index_data_json.score));
	};
	
	const show_all_user_data = async () => {
		let url;
		show_all
			? url = API + `/web/v1/index-prediction/user_name/${user_name}`
			: url = API + `/web/v1/index-prediction/user_name/${user_name}?get_all=true`;
		
		let res = await fetch(url);
		setUser_data(await res.json());
		setShow_all(!show_all);
	};
	
	if (!actual_consensus_data || !user_data_chart) return <Spinner/>;
	
	const {actual, pre, intra} = actual_consensus_data[indexSelected];
	let local_user_data_chart = user_data_chart[indexSelected].reduce((acc, [date_time, value]) =>
		(acc[date_time] = value, acc), {});
	
	let user_chart_will_null = actual.map(([date_time]) => [date_time, local_user_data_chart[date_time]]);
	
	let the_series = [
		{
			data: [...actual],
			name: 'Actual',
			color: chart_actual_color,
			events: {legendItemClick: () => false}
		},
		{
			data: [...intra],
			name: 'Intra-day',
			color: chart_intra_color,
			visible: false,
		},
		{
			data: [...pre],
			name: 'Pre-market',
			color: chart_pre_color,
			visible: false,
		},
		{
			data: [...user_chart_will_null],
			name: 'Your Guess',
			color: 'green',
			marker: {
				symbol: 'circle',
				enabled: true,
				radius: 1.4
			},
		}
	];
	
	let table_data = {
		columns: columns,
		rows: user_data[indexSelected]
	};
	
	let per_table_data = {
		diff_columns: col_diff,
		diff_rows: diff_user_data,
		score_columns: col_score,
		score_rows: score_user_data
	};
	
	return (
		<div>
			<div className={'cover_pic_container'}>
				<div className={'profile_photo_container'}>
					{
						avatar_url
							? <img className={'profile_photo'} alt={'profile-pic'} src={avatar_url}/>
							: <img className={'profile_photo'} alt={'profile-pic'} src={avatar}/>
					}
					<p>{user_name}</p>
				</div>
				<div className={'stats'}>
					<div className={'stat'}>
						<p>Rank</p>
						<p>xxx</p>
					</div>
					<div className={'stat'}>
						<p>Total Guess</p>
						<p>xxx</p>
					</div>
					<div className={'stat'}>
						<p>Best Guess</p>
						<p>xxx</p>
					</div>
					<div className={'stat'}>
						<p>Worse Guess</p>
						<p>xxx</p>
					</div>
				</div>
				<div><h1>BADGE</h1></div>
				<div><h1>GRAPH</h1></div>
			</div>
			<GraphContainer type={'spline'} dataset={the_series} options={options} button={button} marker={true}/>
			<div className={'table_container'}>
				<TabButton>
					<TabButtonItem
						className={classNames({
							active: indexSelected === 'hsi'
						})}
						tabId='HSI_chart' size={'medium'}
						onClick={() => setIndexSelected('hsi')}
					>
						HSI
					</TabButtonItem>
					<TabButtonItem
						className={classNames({
							active: indexSelected === 'sp'
						})}
						tabId='SP_chart' size={'medium'}
						onClick={() => setIndexSelected('sp')}
					>
						S&P
					</TabButtonItem>
					<Button className="checkboxButton" type={'checkbox'}
					        defaultChecked={show_all}
					        onClick={show_all_user_data}>
						SHOW ALL: {show_all ? 'YES' : 'NO'}
					</Button>
				</TabButton>
				<br/>
				<br/>
				<HistoryTable data={updateTableData(table_data.rows)} columns={table_data.columns}/>
				<br/>
				<br/>
			</div>
			<div className={'lower_container'}>
				<h1>{indexSelected === 'hsi' ? 'HSI' : 'S&P'} Rank</h1>
				<div className={'personal_tables'}>
					<HistoryTable
						style={{border: 'none', boxShadow: 'none', background: '#f7f7f7', width: '50%', textAlign: 'center'}}
						showPagination={false}
						data={per_table_data.diff_rows.slice(0, table_size)}
						columns={per_table_data.diff_columns}/>
					<div style={{marginRight: '7%'}}/>
					<HistoryTable
						style={{border: 'none', boxShadow: 'none', background: '#f7f7f7', width: '50%', textAlign: 'center'}}
						showPagination={false}
						data={per_table_data.score_rows.slice(0, table_size)}
						columns={per_table_data.score_columns}/>
				</div>
				<Button style={{marginTop: '2%', marginBottom: '2%'}} color='success'
				        onClick={() => set_table_size(table_size + 5)}>Load More</Button>
			</div>
			< br/>
			< QuestionsSection
				questions={questions}
				answers={answers}
				route={'/how-to-play/#HowToPlayMenuHead'}
				routes={routes}
			/>
		</div>
	)
};

export default MyBoard;
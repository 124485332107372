import React from 'react';
import ProductCard from '../ProductCard';
import './ProductSection.css';
import chart from '../../../../assets/img/chart.svg';
import magnifier from '../../../../assets/img/magnifier.svg';

const CardData = [
  {
    title: 'Stock Analyser',
    content: 'Use our Stock Screener to search and filter stocks easily by multiple filters such as stock price, market cap, dividend yield and more.',
    ButtonText: 'See Product',
    icon: magnifier
  },
  {
    title: 'Share Repurchase Signal',
    content: 'A share repurchase is a transaction whereby a company buys back its own shares from the marketplace. A company might buy back its shares because management considers them undervalued.',
    ButtonText: 'See Product',
    icon: chart
  }
];

const ProductSection = () =>
  <div className="similar-companies">
    <div className="similar-companies__container container">
      <div className="row">
        <div className="col">
          <div className='hr-text'>
            <div className='hr-text__container d-flex align-items-center'>
              <div className='hr-text__line flex-grow-1'/>
              <div className='hr-text__text'>Use our products to get more accurate result</div>
              <div className='hr-text__line flex-grow-1'/>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center py-3">
        <div>
          <div className={'ProductSection'}>
            {CardData.map((data, i) => {
              return (
                <div style={{
                  height: 'inherit',
                  boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1)',
                  margin: '20px'
                }} key={i}>
                  <ProductCard icon={data.icon} title={data.title} content={data.content} ButtonText={data.ButtonText}/>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  </div>;

export default ProductSection;

import {main_site_url} from './constantsFile'

export const getUser = async () => {
	// const wallace =  {display_name: 'Wallace Cheung', uuid: '0cb2fe22-da4d-499b-942b-7fa5df0574ce'};
	// const flask = {display_name: 'Flask.JS22', uuid: 'd0dc3968-76e4-4463-804c-acb01eb9146b'};
	// return new Promise(resolve => setTimeout(() => resolve(wallace), 2000));
	
	let currentUser = await fetch(main_site_url + '/api/users/currentUser', {
		credentials: 'include',
	});
	
	if (currentUser.ok) {
		let res = await currentUser.json();
		console.log('---auth get_user\n', res);
		return res.user;
	}
};

export const logout = async () => {
	let logout = await fetch(main_site_url + '/api/users/logout', {
		credentials: 'include',
	});
	let res = await logout.json();
	console.log('---auth logout\n', res);
	return res.ok;
};
import React from 'react';
import './HowToPlay.css'
import {answers, questions, source} from "./_data";
import HowToPlayAnswer from "./HowToPlayAnswer";

const HowToPlayContent = ({hideAll, locationHash}) =>
	<div className="display_container">
		{
			questions.map((question, i) =>
				<HowToPlayAnswer
					key={'HowToPlayAnswer' + i}
					question={question}
					answer={i < answers.length ? answers[i] : ""}
					id={i}
					source={source[i]}
					locationHash={locationHash}
					hideAll={hideAll}/>
			)
		}
	</div>;

export default HowToPlayContent;
import React, {useState} from 'react';
import HamburgerSideDrawer from "./HamburgerSideDrawer";
import './Hamburger.css'
import hamburger from '../../assets/img/hamburger.svg'
import OutsideAlerter from "../../component/ClickOutside";

const Hamburger = () => {
	const [show, setShow] = useState(false);
	return <OutsideAlerter onClick={() => setShow(false)}>
        <img className="hamburger" src={hamburger} alt={'hamburger'} onClick={() => setShow(!show)}/>
        <HamburgerSideDrawer show={show} toggleShow={() => setShow(!show)}/>
    </OutsideAlerter>
};

export default Hamburger;
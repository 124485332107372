import React, {Component} from 'react';
import ReactHighcharts from 'react-highcharts/ReactHighstock';
import Highcharts from 'react-highcharts';

Highcharts.Highcharts.setOptions({
  time: {
    useUTC: false
  }
});
export default class LineGraph extends Component {
  render() {
    return <ReactHighcharts config={this.props.config}/>;
  }
}

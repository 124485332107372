import React, {useState, useEffect} from 'react';
import './HowToPlay.css'
import HowToPlayContent from './HowToPlayContent'

const HowToPlay = ({location}) => {
	const [hideAll, setHideAll] = useState(true);
	useEffect(() => {
		if (!hideAll)
			setHideAll(true)
	}, [hideAll]);

	return (
		<div className="grey">
			<div className="section__container container container-fluid">
				<HowToPlayContent hideAll={hideAll} locationHash={location.hash}/>
			</div>
			<span className={'emoji-button'} onClick={() => setHideAll(false)} role={'img'}
						aria-label={'hide-all'}>
            ✖️
        </span>
			<span style={{bottom: '53%'}} className={'emoji-button'} role={'img'} aria-label={'back-to-top'}
						onClick={() => window.scroll({top: 0, behavior: 'smooth'})}>
            🔝
        </span>
		</div>
	);
};

export default HowToPlay;

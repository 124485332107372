import React from 'react';
import QuestionCard from './QuestionCard';
import {HashLink as Link} from 'react-router-hash-link';
import './QuestionsSection.css';
import Button from 'reactstrap/es/Button';

const QuestionsSection = ({questions, answers, routes, route}) =>
  <div className={'questionBackground'}>
    <div className={'questions'}>
      {
        questions.map((question, i) =>
          <QuestionCard key={'QuestionsSection' + i} question={question} answers={answers[i]} route={routes[i]}/>
        )
      }
      <div className={'minimal_center'} style={{margin: '10px'}}>
        <Link to={route}>
          <Button className={'buttonQA'}><strong>More Q&A</strong></Button>
        </Link>
      </div>
    </div>
  </div>;

export default QuestionsSection;

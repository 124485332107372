import React from 'react';
import PropTypes from 'prop-types';

export default function Button (props) {
  const {target, attr, divStyle, prefixIcon, suffixIcon, text, type, id, onClick, disabled} = props;
  let bootstrapClass = '';
  
  const getButtonStyle = (style) => {
    let buttonStyle = [];
    const {size, color, outline, shadow, pill} = style;
    switch (size) {
      case 'xl':
        buttonStyle.push('button--xl');
        break;
      case 'lg':
        buttonStyle.push('button--lg');
        break;
      case 'sm':
        buttonStyle.push('button--sm');
        break;
      default:
        buttonStyle.push('button--md');
    }
    
    let colorClass = '';
    if (outline) colorClass = '-outline';
    switch (color) {
      // bootstrap
      case 'primary':
      case 'secondary':
      case 'success':
      case 'info':
      case 'warning':
      case 'danger':
        bootstrapClass = `btn btn${colorClass}-${color}`;
        break;
      
      // custom
      case 'orange':
      case 'navy':
      case 'green':
      case 'red':
      case 'black':
        colorClass = `button--${color}${colorClass}`;
        break;
      
      default:
        colorClass = `button--orange${colorClass}`;
    }
    if (!bootstrapClass) buttonStyle.push(colorClass);
    if (shadow) buttonStyle.push('button--shadow');
    if (pill) buttonStyle.push('button--pill');
    return buttonStyle.join(' ');
  };
  
  return target ? (
    <div className={`button d-inline-block button--popup ${getButtonStyle(props)} ${attr}`}
         style={divStyle}>
      <a className={'d-inline-block'} href={target}>
        {prefixIcon}
        <span className={'text'}>{text}</span>
        {suffixIcon}
      </a>
    </div>
  ) : (
    <div className={`button d-inline-block ${getButtonStyle(props)} ${attr}`}
         style={divStyle}>
      <button type={type || 'button'} id={id} className={bootstrapClass}
              disabled={disabled} onClick={onClick}>
        {prefixIcon}
        {text}
        {suffixIcon}
      </button>
    </div>
  );
}

Button.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']), // 'md' (default)
  color: PropTypes.oneOf(['orange', 'navy', 'green', 'red', 'black',
    'primary', 'secondary', 'success', 'info', 'warning', 'danger']), // 'orange' (default)
  outline: PropTypes.bool,
  shadow: PropTypes.bool,
  pill: PropTypes.bool,
  attr: PropTypes.string, // extra class
  onClick: PropTypes.func,
  target: PropTypes.string, // hyperlink
  prefixIcon: PropTypes.element,
  suffixIcon: PropTypes.element
};

import React, {useState, useEffect} from 'react';
import SourceButton from '../../reactBasic/components/basics/SourceButton'
import './HowToPlay.css'
import upArrow from '../../../src/reactBasic/assets/img/arrow_up.png'
import downArrow from '../../../src/reactBasic/assets/img/arrow_down.png'
import TableQ1 from '../assets/formula/TableForQuestion1.png'
import TableQ7a from '../assets/formula/Table1ForQuestion7.png'
import TableQ7b from '../assets/formula/Table2ForQuestion7.png'
import TableQ18a from '../assets/formula/TableSpecialCase1.png'
import TableQ18b from '../assets/formula/TableSpecialCase2.png'
import TableQ18c from '../assets/formula/TableSpecialCase3.png'
import TableQ18d from '../assets/formula/TableSpecialCase4.png'

const renderTable = (id) => {
	switch (id) {
		case 0:
			return (
				<img className={'Table'} src={TableQ1} alt={'table1'}/>
			);
		case 6:
			return (
				<>
					<img className={'Table'} src={TableQ7a} alt={'table1'}/>
					<img className={'Table'} src={TableQ7b} alt={'table1'}/>
				</>
			);
		case 17:
			return (
				<>
					<img className={'Table'} src={TableQ18a} alt={'table1'}/>
					<img className={'Table'} src={TableQ18b} alt={'table1'}/>
					<img className={'Table'} src={TableQ18c} alt={'table1'}/>
					<img className={'Table'} src={TableQ18d} alt={'table1'}/>
				</>
			);
		default:
			return;
	}
};

const HowToPlayAnswer = ({hideAll, id, question, answer, source, locationHash}) => {
	const [display, setDisplay] = useState(false);

	useEffect(() => {
		if (hideAll){
			setDisplay(false);
		}
	}, [hideAll]);

	useEffect(() => {
		if (('#q' + (id + 1)) === locationHash){
			setDisplay(true);
		}
	}, [id, locationHash]);

	return (
		<div id={'q' + (id + 1)} className={'HowToPlayAnswer'} onClick={() => console.log(display)}>
			<div onClick={() => setDisplay(!display)}>
				<strong> {(id + 1)}.&nbsp;{question}</strong>
				&nbsp;&nbsp;&nbsp;
				{
					display
						? <img src={upArrow} alt={'upArrow'}/>
						: <img src={downArrow} style={{webkitFilter: "invert(100%)"}} alt={'downArrow'}/>
				}
				<br/>
				<br/>
			</div>
			<div style={{
				flexDirection: "column",
				display: display ? "flex" : "none"
			}}>
				<i className={"HowToPlayAnswers"}>{answer}</i>
				{renderTable(id)}
				<SourceButton source={source} text={[1, 2].includes(id) ? " (PDF)" : " (Website)"}/>
			</div>
		</div>
	);
};

export default HowToPlayAnswer;

import React from 'react';
import Button from 'react-bootstrap/Button';
import './ChampionUpper.css'
import {API, orange_color} from "../../../utilities/constantsFile";
import moment from "moment-timezone";
import Popup from "reactjs-popup";
import DayPicker from "react-day-picker";

export default class ChampionUpper extends React.Component {
	state = {
		market_selected: 'sp',
		gaming_type: 'diff',
		avatar_url: [],
		selectedDay: null,
		data: {
			'diff': {'date': {}, 'hsi': [], 'sp': []},
			'score': {'date': {}, 'hsi': [], 'sp': []}
		},
		diff_round: null,
		score_round: null,
		max_diff_round: null,
		max_score_round: null,
		dataLoaded: false,
	};

	toggle_gaming_type = () => {
		if (this.state.gaming_type === 'diff') {
			this.setState({gaming_type: 'score'}, () => this.get_user_profile_photo());
		} else if (this.state.gaming_type === 'score') {
			this.setState({gaming_type: 'diff'}, () => this.get_user_profile_photo());
		}
	};

	toggle_market_type = ({market_selected} = this.state) => {
		if (market_selected === 'sp') {
			for (let element of document.getElementsByClassName('hsi')) {
				element.style.display = 'block'
			}
			for (let element of document.getElementsByClassName('sp')) {
				element.style.display = 'none '
			}
			this.setState({
				market_selected: 'hsi'
			})
		} else {
			for (let element of document.getElementsByClassName('sp')) {
				element.style.display = 'block'
			}
			for (let element of document.getElementsByClassName('hsi')) {
				element.style.display = 'none'
			}
			this.setState({
				market_selected: 'sp'
			})

		}
	};

	handleDayClick = (day, {selected}) => {
		this.setState({
			selectedDay: selected ? undefined : day,
		}, async () => {
			let day_for_api = moment(this.state.selectedDay).format('YYYY-MM-DD');
			await this.get_other_rank_one_data(day_for_api);
		});
	};

	componentDidMount = async () => {
		await this.get_last_rank_one_data();
		await this.get_user_profile_photo();
	};

	get_other_rank_one_data = async (round) => {
		const {gaming_type, data} = this.state;
		let url = API + `/web/v1/index-prediction/rank_one/${round}?gaming_type=${gaming_type}`;
		let res = await fetch(url);
		res = await res.json();
		res = res[gaming_type];
		let holder = data;
		holder[gaming_type] = res;
		this.get_user_profile_photo();
		this.setState({data: holder, [gaming_type + '_round']: res.date.round});
	};

	get_last_rank_one_data = async () => {
		let res = await fetch(API + `/web/v1/index-prediction/rank_one`);
		res = await res.json();

		this.setState({
			dataLoaded: true,
			data: res,
			diff_round: res.diff.date.round,
			score_round: res.score.date.round,
			max_diff_round: res.diff.date.round,
			max_score_round: res.score.date.round
		});
	};

	get_user_profile_photo = async ({gaming_type, data} = this.state) => {
		let photo_data = await Promise.all([
			data[gaming_type].hsi[0]
			&&
			fetch(`${API}/web/v1/users?display_name=${data[gaming_type].hsi[0].user_name}`),
			data[gaming_type].sp[0]
			&&
			fetch(`${API}/web/v1/users?display_name=${data[gaming_type].sp[0].user_name}`)
		]);

		let avatar_url = await Promise.all([
			photo_data[0]
			&&
			await photo_data[0].json(),
			photo_data[1]
			&&
			await photo_data[1].json()
		]);

		avatar_url = avatar_url.map(i => {
			if (i === undefined)
				return 'https://secure.gravatar.com/avatar/68da0796fe5d2bfb4489209ae0ba5944?s=250&d=mm&r=g';
			else if (i.hasOwnProperty('profile_photo'))
				return i.profile_photo;
			else
				return 'https://secure.gravatar.com/avatar/68da0796fe5d2bfb4489209ae0ba5944?s=250&d=mm&r=g';
		});
		this.setState({avatar_url: avatar_url});
	};

	p1 = ({gaming_type, data, market_selected} = this.state) => {
		let table_start = data[gaming_type].date.start_date.slice(5);
		let table_end = data[gaming_type].date.end_date.slice(5);

		return (
			<div className="rowCenter upperHeadings" style={{'justifyContent': 'space-evenly', 'padding': '30px'}}>
				<h2 style={{'fontWeight': '700', 'color': 'white'}}
						onClick={() => console.log(this.state)}>
					{gaming_type === 'diff' ? ' Diff Game ' : ' Score Game '} Champion
				</h2>
				<b style={{color: orange_color, paddingTop: '2%'}} onClick={this.toggle_gaming_type}>
					Switch to {gaming_type === 'diff' ? ' Score Game ' : ' Diff Game '}
				</b>
				<b className={'hsi_sp_switch'} style={{color: orange_color, paddingTop: '1%'}}
					 onClick={() => this.toggle_market_type()}>
					Switch to {market_selected === 'sp' ? ' HSI ' : ' S&P 500 '}
				</b>
				<div className={'calendar'}>
					{
						this.state[`${gaming_type}_round`] > 1
							?
							<Button
								onClick={() => this.get_other_rank_one_data(this.state[`${gaming_type}_round`] - 1)}>
								&#8678;
							</Button>
							:
							<Button disabled> &#8678; </Button>
					}
					<Popup trigger={<Button className="dateButton btn-dark"> {table_start} to {table_end} </Button>}
								 closeOnDocumentClick
								 contentStyle={{'width': '250px'}}>
						<DayPicker
							month={this.state.selectedDay}
							onDayClick={this.handleDayClick}
							selectedDays={this.state.selectedDay}
							disabledDays={[{daysOfWeek: [0, 6]}, day => day > (new Date())]}
						/>
					</Popup>
					{
						this.state[`${gaming_type}_round`] < this.state[`max_${gaming_type}_round`]
							?
							<Button
								onClick={() => this.get_other_rank_one_data(this.state[`${gaming_type}_round`] + 1)}>
								&#8680;
							</Button>
							:
							<Button disabled> &#8680; </Button>
					}
				</div>
				<b style={{color: orange_color, paddingTop: '10px'}}>
					Round {data[gaming_type].date.round}
				</b>
			</div>
		)
	};

	p2 = ({gaming_type, data, avatar_url} = this.state) =>
		<div className={'rowCenter'}>
			{
				['sp', 'hsi'].map((market, i) =>
					data[gaming_type][market][0]
					&&
					<div className={`championPic ${market}`}>
						<p className={`championDiffName  ${market}`}>
							{market === 'hsi' ? 'HSI' : 'S&P 500'}
						</p>
						<div className={'imageContainer'}>
							<img className={`pictureImg ${market}`} alt={gaming_type + '_winner'} src={avatar_url[i]}/>
						</div>
						<p className={`championDiffName ${market}`}>
							{data[gaming_type][market][0].user_name}
						</p>
					</div>
				)
			}
		</div>;

	p3 = ({gaming_type, data} = this.state) =>
		<div className={'rowCenter'}>
			{
				['sp', 'hsi'].map((market) =>
					data[gaming_type][market][0]
					&&
					<div className={`card_box ${market}`}>
						<div className={`championCardBodyNum ${market}`} style={{fontSize: '1.9rem'}}>
							{
								gaming_type === 'diff'
									?
									data[gaming_type][market][0].avg_diff + '%'
									:
									data[gaming_type][market][0].sum_score
							}
						</div>
					</div>
				)
			}
		</div>;

	p4 = ({gaming_type, data} = this.state) =>
		[1, 2].map(i =>
			<div key={'p4' + i} className={'rowCenter'} style={{paddingTop: '20px'}}>
				{
					['sp', 'hsi'].map(market =>

						<div className={`card_box championCardBodyNum ${market}`}>
							<span style={{float: 'left', marginLeft: '2%'}}>
								{
									data[gaming_type][market][i]
									&&
									data[gaming_type][market][i].user_name
								}
							</span>
							<span style={{float: 'right', marginRight: '2%'}}>
								{
									data[gaming_type][market][i]
									&&
									(
										gaming_type === 'diff'
											?
											data[gaming_type][market][i].avg_diff + '%'
											:
											data[gaming_type][market][i].sum_score
									)
								}
							</span>
						</div>
					)
				}
			</div>
		);

	render() {
		return (
			(this.state.dataLoaded)
			&&
			<div className={'pic2'}>
				<div className={'container'}>
					{this.p1()}
					{this.p2()}
					{this.p3()}
					{this.p4()}
				</div>
			</div>
		)
	}
}

import React, {Component} from 'react';
import {API} from '../../../utilities/constantsFile'
import {TabButton, TabButtonItem} from '../../../reactBasic/components/basics/Tab';
import classNames from 'classnames';
import {MDBDataTable} from "mdbreact";
import {Card, CardBody, Row, Col} from 'reactstrap';
import Button from 'react-bootstrap/Button';
import DayPicker from 'react-day-picker';
import './calendar.css'
import './RoundWinner.css'
import Popup from "reactjs-popup";
import moment from 'moment-timezone'
import table_data_json from './table_data'
import {Link} from "react-router-dom";
import Spinner from '../../component/Spinner';

const updateTableData = (data) => {
	data.forEach(datum => {
		datum['user_name'] = <Link to={{pathname: `/my-board/${datum['user_name']}`}} user_name={datum['user_name']}> {datum['user_name']} </Link>;
		}
	);
	return data
};

export default class RoundWinner extends Component {
	state = {
		gaming_type: 'diff',
		data: {
			'diff': {'date': {round: null}, 'hsi': [], 'sp': []},
			'score': {'date': {round: null}, 'hsi': [], 'sp': []}
		},
		data_backup: {},
		selectedDay: null,
		diff_round: null,
		score_round: null,
		max_diff_round: null,
		max_score_round: null,
	};
	
	handleDayClick = (day, {selected}) => {
		this.setState({
			selectedDay: selected ? undefined : day,
		}, async () => {
			let day_for_api = moment(this.state.selectedDay).format('YYYY-MM-DD');
			await this.get_other_round_winner(day_for_api);
		});
	};
	
	componentDidMount() {
		this.get_last_round_winner().then();
	}
	
	get_other_round_winner = async (round_or_date) => {
		if (round_or_date <= 0)
			return;
		
		let url = API + `/web/v1/index-prediction/round_winner/${round_or_date}`;
		let res = await fetch(url);
		res = await res.json();
		res.diff.sp = updateTableData(res.diff.sp);
		res.diff.hsi = updateTableData(res.diff.hsi);
		res.score.sp = updateTableData(res.score.sp);
		res.score.hsi = updateTableData(res.score.hsi);		res.diff.sp = updateTableData(res.diff.sp);
		res.diff.hsi = updateTableData(res.diff.hsi);
		res.score.sp = updateTableData(res.score.sp);
		res.score.hsi = updateTableData(res.score.hsi);
		this.setState({
			data: res, diff_round: res.diff.date.round, score_round: res.score.date.round, data_backup: res
		})
	};
	
	get_last_round_winner = async () => {
		let url = API + '/web/v1/index-prediction/round_winner/';
		let res = await fetch(url);
		res = await res.json();
		this.setState({
			data: res,
			diff_round: res.diff.date.round, score_round: res.score.date.round,
			max_diff_round: res.diff.date.round, max_score_round: res.score.date.round,
			data_backup: res
		})
	};
	filterData = (e) => {
		let query = e.target.value;
		
		if (!query) {
			return this.setState({data: this.state.data_backup})
		}
		query = query.toLowerCase();
		
		let data_filtered = this.state.data_backup[this.state.gaming_type];
		let {hsi, sp} = data_filtered;
		hsi = hsi.filter(element =>  element.user_name.props.user_name.toLowerCase().indexOf(query) !== -1);
		sp = sp.filter(element => element.user_name.props.user_name.toLowerCase().indexOf(query) !== -1);
		this.setState({data: {[this.state.gaming_type]: {hsi: hsi, sp: sp, date: data_filtered.date}}});
	};
	
	render() {
		let {diff_round, score_round, max_diff_round, max_score_round, gaming_type, data} = this.state;
		if (!data.diff.date.round)
			return <Spinner/>;
		
		let table_col = table_data_json[`${gaming_type}_col_name`],
			left_table_data = data[gaming_type].hsi,
			right_table_data = data[gaming_type].sp,
			table_round = data[gaming_type].date.round,
			table_start = data[gaming_type].date.start_date.slice(5),
			table_end = data[gaming_type].date.end_date.slice(5);
		
		return (
			<div className={'section__container container'}>
				<h1 style={{textAlign: 'center'}}>Round Winner</h1>
				<TabButton>
					<TabButtonItem
						className={classNames({active: this.state.gaming_type === 'diff'})}
						onClick={() => this.setState({gaming_type: 'diff'})}>
						Diff Game
					</TabButtonItem>
					<TabButtonItem
						className={classNames({active: this.state.gaming_type === 'score'})}
						onClick={() => this.setState({gaming_type: 'score'})}>
						Score Game
					</TabButtonItem>
				</TabButton>
				<br/>
				<br/>
				<Card>
					<CardBody>
						<Row style={{'borderBottom': 'solid 1px #DDD'}}>
							<Col xs='4' sm='4' lg='4'>
								<b style={{'fontSize': '20px'}}>Round: {table_round}</b>
							</Col>
							<Col xs='6' sm='6' lg='6' style={{textAlign: 'right', 'marginBottom': '20px'}}>
								{
									gaming_type === 'diff'
										?
										diff_round > 1
											? <Button onClick={() => this.get_other_round_winner(diff_round - 1)}>&#8678;</Button>
											: <Button disabled> &#8678;</Button>
										:
										score_round > 1
											? <Button onClick={() => this.get_other_round_winner(score_round - 1)}>
												&#8678;</Button>
											: <Button disabled> &#8678;</Button>
								}
								<Popup
									trigger={<Button className="dateButton btn-dark"> {table_start} to {table_end} </Button>}
									closeOnDocumentClick contentStyle={{'width': '250px'}}>
									<DayPicker
										month={this.state.selectedDay}
										onDayClick={this.handleDayClick}
										selectedDays={this.state.selectedDay}
										disabledDays={[{daysOfWeek: [0, 6]}, day => day > (new Date())]}
									/>
								</Popup>
								{
									gaming_type === 'diff'
										?
										diff_round < max_diff_round
											? <Button onClick={() => this.get_other_round_winner(diff_round + 1)}>&#8680;</Button>
											: <Button disabled> &#8680; </Button>
										:
										score_round < max_score_round
											? <Button onClick={() => this.get_other_round_winner(score_round + 1)}>&#8680;</Button>
											: <Button disabled> &#8680; </Button>
								}
							</Col>
							<Col xs='2' sm='2' lg='2'>
								<input style={{width: '100px'}} type={'text'} placeholder={'search'} onChange={this.filterData}/>
							</Col>
						</Row>
						
						<Row className={'UglyLeaderboardRow'}>
							<Col>
								<h3 style={{textAlign: 'center', 'fontWeight': '700'}}>HSI</h3>
								<MDBDataTable
									searching={false}
									displayEntries={false}
									className={'MDBDataTable'}
									autoWidth={true}
									entries={10}
									responsive={true}
									paginationLabel={["<", ">"]}
									data={{columns: table_col, rows: updateTableData(left_table_data)}}/>
							</Col>
							<Col>
								<h3 style={{textAlign: 'center', 'fontWeight': '700'}}>S&P</h3>
								<MDBDataTable
									searching={false}
									displayEntries={false}
									className={'MDBDataTable'}
									autoWidth={true}
									responsive={true}
									entries={10}
									paginationLabel={["<", ">"]}
									data={{columns: table_col, rows: updateTableData(right_table_data)}}/>
							</Col>
						</Row>
					</CardBody>
					<p onClick={() => console.log(this.state)}>* The count only represents the participate rate of Pre-Market
						prediction.</p>
				</Card>
			</div>
		)
	}
}
import React, {useState, useEffect, useContext} from 'react';
import moment from "moment-timezone";
import {API, green_color, red_color, orange_color, API_data} from '../../utilities/constantsFile';
import up_svg from '../assets/img/up.svg';
import down_svg from '../assets/img/down.svg';
import CommentModal from "./CommentModal";
import './LandingPage.css'
import UserContext from "../../Context";
import fetch from "node-fetch";
import {Roller} from 'react-css-spinners'
import Button from "../../reactBasic/components/basics/Button";

const fmt = 'HH:mm:ss';
const tmz = {hsi: 'Asia/Hong_Kong', sp: 'America/New_York'};


const error_msg = () => <h5 style={{color: "red"}}><br/><br/><br/>fail to get data<br/>please refresh</h5>;

const PredictionBox = () => {
	const {userData} = useContext(UserContext);
	const [index_price, setIndex_price] = useState({
		hsi_prev_close: null,
		hsi_diff: null,
		hsi_pct: null,
		hsi_date: null,
		hsi_value: null,
		sp_diff: null,
		sp_value: null,
		sp_pct: null,
		sp_date: null,
		sp_prev_close: null,
	});
	const [status, setStatus] = useState('');
	const [predictability, setPredictability] = useState({sp: null, hsi: null, error: false});
	const [timing, setTiming] = useState({sp: null, hsi: null});
	const [market_selected, setMarket_selected] = useState('sp');
	const [imply_change, setImply_change] = useState({
		point: '',
		point_change: '',
		perct_change: '',
	});
	const [message, setMessage] = useState('');
	const [comment, setComment] = useState('');
	const [after_prediction, setAfter_prediction] = useState({
		consensus: null,
		remaining_count: null,
		no_more_prediction: null
	});
	
	let user_name, uuid;
	if (userData) {
		user_name = userData.display_name;
		uuid = userData.uuid;
	}
	
	useEffect(() => {
			setMarket_selected(predictability.sp ? 'sp' : 'hsi');
		}, [predictability]
	);
	
	const get_index_price = async (fetching_failure_count = 0) => {
		// try 7 times before showing failure.
		if (fetching_failure_count > 1) return setIndex_price({sp: null, hsi: null, error: true});
		let res = await fetch(API + '/web/v1/index-prediction/both_index');
		if (!res.ok) return setTimeout(() => get_index_price(fetching_failure_count + 1), 1000);
		setIndex_price(await res.json());
	};
	
	useEffect(() => {
		Promise.all([get_index_price(), check_tradability()]).then()
	}, []);
	
	const check_tradability = async () => {
		let sp_time = moment().tz(tmz.sp).format('YYYY-MM-DD');
		let hsi_time = moment().tz(tmz.hsi).format('YYYY-MM-DD');
		let [sp_res, hsi_res] = await Promise.all([
			fetch(API_data + `/market/trading-calendar/nasdaq?start_date=${sp_time}&last=1`),
			fetch(API_data + `/market/trading-calendar/sehk?start_date=${hsi_time}&last=1`)
		]);
		[sp_res, hsi_res] = await Promise.all([sp_res.json(), hsi_res.json()]);
		return get_message({
			'sp': sp_res.date_time[0] === sp_time,
			'hsi': hsi_res.date_time[0] === hsi_time
		});
	};
	const get_message = (tradability) => {
		let predictability = {}, timing = {}, message = {};
		
		Object.keys(tradability).forEach(_index => {
			if (tradability[_index]) {
				let time = moment.tz(tmz[_index]), pre_start = moment.tz('02:00:00', fmt, tmz[_index]),
					pre_end = moment.tz('09:00:00', fmt, tmz[_index]),
					intra_end = moment.tz('14:00:00', fmt, tmz[_index]), ps_ms = pre_start.diff(time)		// ps_ms means pre start - current time in ms
					, pe_ms = pre_end.diff(time)			// pe_ms means pre end - current time in ms
					, ie_ms = intra_end.diff(time);		// ie_ms means intra end - current time in ms
				if (ps_ms > 0) {		// current time < 0200 am
					timing[_index] = null;
					predictability[_index] = false;
					ps_ms > 3600000		// more than an hour
						? message[_index] = 'Prediction will be opened in ' + Math.floor(moment.duration(ps_ms).asHours()) + ' hrs ' + moment.utc(ps_ms).format("mm") + ' mins'
						: message[_index] = 'Prediction will be opened in ' + moment.utc(ps_ms).format("mm") + ' mins';
				} else if (pe_ms > 0) {		// current time in between of 0200am and 0900 am
					timing[_index] = 'pre';
					predictability[_index] = true;
					pe_ms > 3600000		// more than an hour
						? message[_index] = 'Market will be opened in ' + Math.floor(moment.duration(pe_ms).asHours()) + ' hrs ' + moment.utc(pe_ms).format("mm") + ' mins'
						: message[_index] = 'Market will be opened in ' + moment.utc(pe_ms).format("mm") + ' mins';
				} else if (ie_ms > 0) {		// current time in between of 0900am and 0200pm
					timing[_index] = 'intra';
					predictability[_index] = true;
					ie_ms > 3600000		// more than an hour
						? message[_index] = 'Prediction will be closed in ' + Math.floor(moment.duration(ie_ms).asHours()) + ' hrs ' + moment.utc(ie_ms).format("mm") + ' mins'
						: message[_index] = 'Prediction will be closed in ' + moment.utc(ie_ms).format("mm") + ' mins'
				} else {		// after 0200pm of the trading day, no prediction.
					message[_index] = 'Prediction is closed.';
					predictability[_index] = false;
					timing[_index] = null;
				}
			} else {		// ${_index} is not tradable
			    _index === 'hsi'
			    ? message[_index] = `HKEX is closed today.HSI prediction game will be resumed on next trading day.`
			    : message[_index] = `S&P 500 is closed today.S&P 500 prediction game will be resumed on next trading day.`;
				predictability[_index] = false;
				timing[_index] = null;
			}
		});
		setMessage(message);
		setPredictability(predictability);
		setTiming(timing);
	};
	const rise_fall_not_selected_page = () => {
		let data_date = moment(index_price[market_selected + '_date']).format('ddd DD-MMM'),
			current_date = moment.tz(tmz[market_selected]).format('ddd DD-MMM'),
			value = index_price[market_selected + '_value'],
			diff = index_price[market_selected + '_diff'],
			pct = index_price[market_selected + '_pct'];
		return (
			<>
				<p className={'prediction_box_title'}>
					What is the closing price for{market_selected === 'sp' ? ' S&P ' : ' HSI '}today?
				</p>
				<i>{data_date}&nbsp;&nbsp;&nbsp;{data_date === current_date ? 'current index level' : 'previous closing price'}</i>
				
				<div className={'minimal_center'}>
					<p className={'prediction_box_number'}>{value}&nbsp;</p>
					<p style={diff >= 0 ? {color: green_color, marginTop: '1%'} : {color: red_color}}>{pct}%<br/>{diff}</p>
				</div>
				
				<div className={'minimal_center'}>
					{
						predictability[market_selected]
						&&
						<>
							<button style={{borderColor: green_color, color: green_color}}
							        onClick={() => set_rise_fall('+')} className={'rise_fall_box'}>
								UP
							</button>
							<button style={{borderColor: red_color, color: red_color}}
							        onClick={() => set_rise_fall('-')} className={'rise_fall_box'}>
								DOWN
							</button>
						</>
					}
				</div>
				<br/>
				<p>{message[market_selected]}</p>
				{
					timing[market_selected]
					&&
					<p>Timing: {timing[market_selected] === 'intra' ? 'Intra-day' : 'Pre-market'}</p>
				}
				<br/>
				<b style={{color: orange_color}} onClick={toggle_market}>
					Switch to {market_selected === 'hsi' ? ' S&P ' : ' HSI '}
				</b>
			</>
		);
	};
	const empty_imply_change = () => {
		setImply_change({
			imply_change: {
				point_change: '',
				point: '',
				perct_change: ''
			}
		})
	};
	const set_rise_fall = (rise_or_fall) => {
		if (rise_or_fall === '+') {
			setStatus('+')
		} else if (rise_or_fall === '-') {
			setStatus('-')
		} else if (rise_or_fall === '') {
			empty_imply_change();
			setStatus('')
		}
	};
	const rise_fall_selected_page = () => {
		let market_price = timing[market_selected] === 'pre' ? index_price[market_selected + '_value'] : index_price[market_selected + '_prev_close'];
		
		return (
			<>
				<p style={{fontSize: 30, marginTop: '20px'}}>
					{
						imply_change.point === ''
							? market_price
							: <>
								{market_price}
								&nbsp;&rarr;&nbsp;
								<span style={{color: status === '+' ? green_color : red_color}}>{imply_change.point}</span>
							</>
					}
				</p>
				
				<div className={'minimal_center'} style={{marginTop: '50px', marginBottom: '50px', alignItems: 'flex-start'}}>
					<img style={{margin: '10px'}} onClick={toggle_rise_fall} src={status === '+' ? up_svg : down_svg}
					     alt={"up/down"}/>
					
					<div style={{paddingRight: '1%'}}>
						<input value={imply_change.point_change} min="0" id={"point_input"}
						       type={'number'} onChange={(e) => handle_point_change(market_price, e)}/>
						<label htmlFor={"point_input"}><sub>point</sub></label>
					</div>
					<div>
						<input value={imply_change.perct_change} min="0" max="100"
						       id={"perct_input"} type={'number'} onChange={(e) => handle_perct_change(market_price, e)}/>
						<label htmlFor={"perct_input"}><sub>%</sub></label>
					</div>
					
					<CommentModal comment={comment} set_comment_parent={(comment) => setComment(comment)}/>
				</div>
				
				<div>
					<span onClick={() => set_rise_fall('')}>cancel</span>
					<span>&nbsp; | &nbsp;</span>
					<div className=' d-inline-block button--lg-orange'>
						<button className={'buttonConfirm'} onClick={confirm_prediction} type='button'>Confirm</button>
					</div>
				</div>
			</>
		)
	};
	const confirm_prediction = async () => {
		let {point_change, perct_change, point} = imply_change;
		let commitPrediction;
		if (point_change === '')
			return window.alert('please key in your prediction!');
		if (!user_name)
			return window.alert('please login on the main site first!');
		
		if (window.confirm(`You have predicted ${market_selected === 'sp' ? 'S&P' : 'HSI'} to ${status === '+' ? 'rise' : 'fall'} ${perct_change}% today. Confirm?`)) {
			commitPrediction = await fetch(API + '/web/v1/index-prediction/', {
				method: 'post', headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
				body: JSON.stringify({
					"prediction": {
						"user_name": user_name,
						'index': market_selected,
						'percentage': status === '+' ? perct_change : perct_change * -1,
						'point': status === '+' ? point_change : point_change * -1,
						'comment': comment,
						'uuid': uuid,
						'exact_value': point
					}
				})
			});
			commitPrediction.ok
				? setAfter_prediction(await commitPrediction.json())
				: alert('prediction failed due to: ' + JSON.stringify(commitPrediction));
			console.log('commitPrediction.statusText', commitPrediction);				// show everything for me to debug...
			empty_imply_change();
			setStatus('done');
		}
	};
	const after_prediction_is_done = ({consensus, remaining_count, no_more_prediction} = after_prediction) =>
		<>
			<p className={'prediction_box_title'}
			   style={{marginBottom: '2%'}}>{no_more_prediction ? 'Prediction Fail!' : 'Prediction Success!'}</p>
			<i className={'prediction_box_text'} style={{marginTop: '1%'}}>
				{market_selected === 'sp' ? 'S&P' : 'HSI'}&nbsp;&nbsp;{timing[market_selected] === 'pre' ? 'Pre-Market' : 'Intra-day'} Consensus:
			</i>
			{
				consensus
				&&
				<div className={'minimal_center'}>
					<p className={'prediction_box_number'} style={{color: orange_color}}>{consensus.consensus}&nbsp;</p>
					<p style={{color: consensus.point >= 0 ? green_color : red_color}}>
						{consensus.percentage}%<br/>{consensus.point}
					</p>
				</div>
			}
			<p
				style={{marginBottom: '60px'}}>{no_more_prediction ? "No more prediction at this timing." : `Remaining chances: ${remaining_count}`}</p>
			<div className='d-inline-block button--lg-orange'>
				<button className={'buttonConfirm'} onClick={() => setStatus('')} type='button'>Continue</button>
			</div>
		</>;
	
	const toggle_rise_fall = (holder_imply_change = imply_change) => {
		if (status === '+') {
			setStatus('-');
		} else if (status === '-') {
			setStatus('+');
		}
		
		if (holder_imply_change.point_change) {
			if (status === '+') {
				holder_imply_change.point = (parseFloat(holder_imply_change.point) - parseFloat(holder_imply_change.point_change) * 2).toFixed(2);
			} else if (status === '-') {
				holder_imply_change.point = (parseFloat(holder_imply_change.point) + parseFloat(holder_imply_change.point_change) * 2).toFixed(2);
			}
			setImply_change(holder_imply_change)
		}
	};
	
	const handle_point_change = (market_price, e) => {
		let new_perct_change, new_point, old_point = parseFloat(market_price);
		let incoming_change = e.target.value;
		
		if (incoming_change === undefined || incoming_change === null || !incoming_change)
			return empty_imply_change();
		if (incoming_change.length > 7)
			return;
		incoming_change = parseFloat(incoming_change);
		if (incoming_change > old_point)
			return;
		
		if (status === '+') {
			new_point = (old_point + incoming_change).toFixed(2);
			new_perct_change = (incoming_change / old_point * 100).toFixed(2);
		} else if (status === '-') {
			new_point = (old_point - incoming_change).toFixed(2);
			new_perct_change = (incoming_change / old_point * 100).toFixed(2);
		}
		setImply_change({point: new_point, point_change: incoming_change, perct_change: new_perct_change});
	};
	
	const handle_perct_change = (market_price, e) => {
		let new_point_change, new_point, old_point = parseFloat(market_price);
		
		let incoming_change = e.target.value;
		if (incoming_change === undefined || incoming_change === null || !incoming_change)
			return empty_imply_change();
		
		if (incoming_change.length > 5)
			return;
		incoming_change = parseFloat(incoming_change);
		if (incoming_change > 100)
			return;
		
		if (status === '+') {
			new_point = (old_point * (1 + incoming_change / 100)).toFixed(2);
			new_point_change = (new_point - old_point).toFixed(2);
		} else if (status === '-') {
			new_point = (old_point * (1 - incoming_change / 100)).toFixed(2);
			new_point_change = (old_point - new_point).toFixed(2);
		}
		setImply_change({point: new_point, point_change: new_point_change, perct_change: incoming_change});
	};
	
	const toggle_market = () => {
		if (market_selected === 'sp') {
			setMarket_selected('hsi');
		} else if (market_selected === 'hsi') {
			setMarket_selected('sp');
		}
	};
	
	return (
		<>
			{
				userData
				&&
				<>
					<div className={'ButtonContainer'}>
						<Button size={'lg'} color={'orange'} text={'Go to my Board'} target={'/my-board'}/>
					</div>
					<br/>
				</>
			}
			<div className={'flex_margin_center'}>
				<div className={'prediction_box'} onClick={() => console.log(index_price)}>
					{
						status === 'done'
							? after_prediction_is_done()
							:
							<>
								{
									index_price.error
										? error_msg()
										: (
											index_price.hsi_value
												? (
													status === ''
														? rise_fall_not_selected_page()
														: rise_fall_selected_page()
												)
												: <Roller/>
										)
								}
							</>
					}
				</div>
			</div>
		</>
	)
};

export default PredictionBox;



























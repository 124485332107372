import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import by_invbots from '../assets/img/by-invbots.svg';
import invbots_predict from '../assets/img/invbots-predict.svg';
import './NavBar.css'
import {logout} from '../../utilities/auth'
import {API, main_site_url, my_site_url, orange_color, is_prod} from '../../utilities/constantsFile'
import Button from '../../reactBasic/components/basics/Button';
import Hamburger from "./HamburgerNavBar";
import UserContext from '../../Context'
import avatar from "../assets/img/avatar.svg";

const NavBar = () => {
	const [pathName, setPathName] = useState(window.location.pathname);
	const [avatar_url, setAvatar_url] = useState(null);
	const check_dev = !is_prod;
	const _style = check_dev ? {background: '#fff689'} : {background: '#ffffff'};
	const {userData, setUserData} = useContext(UserContext);

	let user_name;
	if (userData)
		user_name = userData.display_name;

	useEffect(() => {
		if (userData) {
			get_user_profile_photo();
		}
	}, [userData]);
	
	const get_user_profile_photo = () => {
		fetch(API + `/web/v1/users?display_name=${user_name}`)
		.then(r => r.json())
		.then(j => {
			if(j.profile_photo) setAvatar_url(j.profile_photo);
			else setAvatar_url(null);
		})
	};
	
	return (
		<nav className={'nav-bar '} style={_style} >
			<ul>
				<li>
					<Hamburger/>
				</li>
				<li style={{marginLeft: '40px'}}>
					<Link to="/" onClick={() => setPathName('/')}>
						<img alt={'invbots_predict'} src={invbots_predict} width="100" height="40"/>
					</Link>
				</li>
				{
					userData
						?
						<div className="right minimal_center">
							{
								avatar_url
									? <img className={'profile_photo_nav'} alt={'profile-pic'} src={avatar_url}/>
									: <img className={'profile_photo_nav'} alt={'profile-pic'} src={avatar}/>
							}
							<p className={'display-name'}>

								{userData.display_name}
							</p>
							<Button divStyle={{margin: '0 10px 0 10px'}} text="logout" bstyle='middle orange'
							        onClick={() => logout().then(setUserData(undefined))}
							/>
						</div>
						:
						<Button divStyle={{margin: '5px 10px 0 10px'}} attr={"right"} text="login" bstyle='middle orange'
                                onClick={() => window.open(`${main_site_url}/login?redirect=${encodeURIComponent(my_site_url)}`, "_self")}/>
				}
				<div className={'hide-in-mobile-view'}>
					<li>
						<a href={main_site_url} rel={'noopener noreferrer'} target={"_blank"}>
							<img alt={'by_invbots'} src={by_invbots} width="100" height="40"/>
						</a>
					</li>
					{
						userData
						&&
						<li>
							<Link to="/my-board" onClick={() => setPathName('/my-board')}>
								<p style={{color: pathName === '/my-board' && orange_color}}>
									My Board
								</p>
							</Link>
						</li>
					}
					{
						[["/how-to-play", 'How To Play'], ["/leader-board", 'Leader board'],
							["/prediction-history", 'Prediction History']].map(link =>
							<li>
								<Link to={link[0]} onClick={() => setPathName(link[0])}>
									<p style={{color: pathName === link[0] ? orange_color : 'black'}}>{link[1]}</p>
								</Link>
							</li>
						)
					}
				</div>
			</ul>
			{
				check_dev
				&&
				<p className={'minimal_center'} style={{color: 'red', margin: 0}}>THIS IS DEV SITE!</p>
			}
		</nav>
	);
};

export default NavBar;
import Highcharts from 'highcharts';

export const theme_orange = '#F05123';
export const theme_navy = '#1B315E';
export const theme_green = '#7DA800';
export const theme_grey = '#D6D6D6';

export const graph_color = ['#f5a623', '#a2eaf6', '#9e9e9e', '#cede73',
  '#ac8362', '#579eea', '#9381C8', '#7da800',
  '#725959', '#153a75'];

export const timestampConverter = dateTime =>
  Date.UTC(parseInt(dateTime.slice(0, 4)), parseInt(dateTime.slice(5, 7)) - 1, parseInt(dateTime.slice(8, 10)));

export const datetimeConverter = (format, timestamp) =>
  Highcharts.dateFormat(format, new Date(timestamp));

export const extractData = (dataset, attr) => {
  const series = dataset.map(data => {
    const timestamp = timestampConverter(data.date_time);
    const dataArray = attr.map(value => {
      return data[value];
    });
    return [timestamp].concat(dataArray);
  });
  return series.reverse();
};

export const areaGradient = (definedColor, index) => {
  const color = definedColor || graph_color[index];
  return {
    linearGradient: {
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 1
    },
    stops: [
      [0, Highcharts.Color(color).setOpacity(0.1).get('rgba')],
      [1, Highcharts.Color(color).setOpacity(0).get('rgba')]
    ]
  };
};

export const labelFormatter = (data, formatter = {}) => {
  const prefix = formatter.prefix || '';
  const postfix = formatter.postfix || '';
  const rounding = formatter.rounding;
  let formattedData;
  switch (postfix) {
    case 'K':
      formattedData = data / 1000;
      break;
    case 'M':
      formattedData = data / 1000000;
      break;
    case 'B':
      formattedData = data / 1000000000;
      break;
    default:
      formattedData = data;
  }
  return `${prefix}${data.toFixed(rounding)}${postfix}`;
};

export const flagBuilder = (flag, title, dateTime) => {
  let flagObj = {};
  flagObj.x = typeof dateTime === 'number' ? dateTime : timestampConverter(dateTime);
  flagObj.title = typeof title === 'number' ? title + 1 : title;
  flagObj.text = '';
  for (const key in flag) {
    if (flag.hasOwnProperty(key)) {
      const attr = key.replace(/_/g, ' ').toUpperCase();
      flagObj.text += `<span style="color:white;"><span style="color:${theme_green};">${attr}: </span>${flag[key]}<br/></span>`;
    }
  }
  return flagObj;
};

export const tickBuilder = (max, min, numOfTicks) => {
  let ticks = [];
  if (max !== null && min !== null) {
    const midPoint = (max - min) / 2;
    let interval = (max - min) / (numOfTicks - 1);
    ticks.push(min + midPoint);
    for (let increment = interval; increment <= midPoint; increment += interval) {
      ticks.push(min + midPoint + increment);
      ticks.push(min + midPoint - increment);
    }
    ticks.sort();
  }
  return ticks;
};

const tooltipHeader = timestamp =>
  `<p style="color:${theme_orange}; padding-bottom:5px; border-bottom:1px solid grey; margin:0">${datetimeConverter('%d/%m/%Y', timestamp)}</p>`;

const tooltipBody = (color, tag, data, formatter, ignore = false) => {
  const formattedData = labelFormatter(data, formatter);
  return (
    ignore ? `<p style="color:white; margin:10px 0px">${formattedData}</p>` :
      `<p style="color:${color}; margin:10px 0px">${tag}<br/>`
      + `<span style="color:white; margin:10px 0px">${formattedData}</span></p>`
  );
};

export const tooltipBuilder = (series, timestamp, formatter) => {
  const attrList = {
    candlestickAttr: ['open', 'close', 'high', 'low']
  };
  const customTooltip = ['candlestick'];
  let defaultTooltip = [...series];
  customTooltip.forEach(type => {
    defaultTooltip = defaultTooltip.filter(item => item.series.userOptions.type !== type);
  });
  let defaultContainer = defaultTooltip.reduce(function (s, point) {
      return s + tooltipBody(point.series.color, point.series.name, point.y, formatter, series.length === 1);
    }, tooltipHeader(timestamp)
  );

  return series.map(point => {
    const type = point.series.userOptions.type;
    if (customTooltip.indexOf(type) === -1 && defaultTooltip[0] !== point)
      return null;
    const container =
      customTooltip.indexOf(type) === -1 ?
        defaultContainer :
        attrList[`${type}Attr`].reduce(function (s, value) {
          return s + tooltipBody(theme_green, value, point.point[value], formatter);
        }, tooltipHeader(timestamp));
    return `<div style="text-align:center">${container}</div>`;
  });
};

import React from 'react';
import logoWhite from '../../../assets/logo/logo--white.svg';
import {Col, Container, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const feedBack = 'https://forms.gle/vyBG9VwiLioHcnJk6';
const facebook = 'https://www.facebook.com/invbots/';
const linkedIn = 'https://www.linkedin.com/company/invest-bots-limited';

const Footer = ({mainSiteURL, predictionSiteURL, feedBackURL = feedBack, facebookURL = facebook, linkedInURL = linkedIn}) =>
  <footer className='footer'>
    <div className='footer__sitemap'>
      <Container className='footer__sitemap-container'>
        <nav className='footer__nav'>
          <Row>
            <Col xs={6} sm={3}>
              <div className='footer__nav-title'>About</div>
              <div className='link link--white footer__nav-content'>
                <a className='link__text' href={mainSiteURL}>Company</a>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className='footer__nav-title'>Products</div>
              {/*<div className='link link--white footer__nav-content'>*/}
              {/*	<a className='link__text' href={mainSiteURL + '/stock-analyser'}>Stock Analyser</a>*/}
              {/*</div>*/}
              <div className='link link--white footer__nav-content'>
                <a className='link__text' href={mainSiteURL + '/share-repurchase'}>Share Repurchase</a>
              </div>
              <div className='link link--white footer__nav-content'>
                <a className='link__text' href={mainSiteURL + '/watchlist-profile'}>Watch List</a>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className='footer__nav-title'>Support</div>
              <div className='link link--white footer__nav-content'>
                <a className='link__text'
                   href={`${mainSiteURL}/login/https%3A%2F%2F${predictionSiteURL}`}>Register</a>
              </div>
              <div className='link link--white footer__nav-content'>
                <a className='link__text' rel={'noopener noreferrer'} target={'_blank'}
                   href={feedBackURL}>Feedback</a>
              </div>
            </Col>
            <Col xs={6} sm={3}>
              <div className='footer__nav-title'>Connect</div>
              <div className='footer__nav-content'>
                <div className='icon icon--social' id='icon-facebook'>
                  <FontAwesomeIcon size="2x" icon={['fab', 'facebook-square']}
                                   onClick={() => window.location.href = facebookURL}/>
                </div>
                <div className='icon icon--social' id='icon-linkedin'>
                  <FontAwesomeIcon size="2x" icon={['fab', 'linkedin']}
                                   onClick={() => window.location.href = linkedInURL}/>
                </div>
              </div>
            </Col>
          </Row>
        </nav>
      </Container>
    </div>
    <div className='footer__copyright'>
      <Container className='footer__copyright-container'>
        <Row style={{alignItems: 'center', margin: 0}}>
          <img className={'footer__copyright-logo'} src={logoWhite} alt={''}/>
          <div className='footer__copyright-text'>©
            2019 Invest Bots, All Rights Reserved.
          </div>
        </Row>
      </Container>
    </div>
  </footer>;


export default Footer;

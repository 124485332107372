import React, {useState} from 'react';
import upArrow from '../../../assets/img/arrow_up.png';
import downArrow from '../../../assets/img/arrow_down.png';
import './QuestionsSection.css';
import {HashLink as Link} from 'react-router-hash-link';
import Button from 'reactstrap/es/Button';

const QuestionsCard = ({question, answers, route}) => {
  const [display, setDisplay] = useState(false);

  return <div className={'boarder'} onClick={() => setDisplay(!display)}>
    <div className={'minimal_center question question2'}>
      {question}
      &nbsp;&nbsp;&nbsp;
      {
        display
          ? <img src={upArrow} style={{webkitFilter: 'invert(100%)'}} alt={'upArrow'}/>
          : <img src={downArrow} alt={'downArrow'}/>
      }
    </div>
    <p className={'minimal_center answers'} style={{display: display ? 'flex' : 'none'}}>
      {answers}
      &nbsp;&nbsp;&nbsp;
      <Link to={route}>
        <Button color={'link'}>Learn More</Button>
      </Link>
    </p>
  </div>;
};

export default QuestionsCard;

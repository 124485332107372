import React, {Component} from 'react';
import {API, chart_actual_color, chart_intra_color, chart_pre_color} from '../../utilities/constantsFile';
import {TabButton, TabButtonItem} from '../../reactBasic/components/basics/Tab';
import classNames from 'classnames';
import GraphContainer from '../../reactBasic/components/modules/Graph/predictionSite';


export default class PredictionChart extends Component {
  state = {
    actual_consensus_data: {
      'hsi': {
        "actual": [],
        "pre": [],
        "intra": []
      },
      "sp": {
        "actual": [],
        "pre": [],
        "intra": []
      }
    },
    indexSelected: null,
    the_series: [{
      data: null,
      name: null,
      color: null,
      visible: null,
    }]
  };

  get_actual_consensus = async () => {
    const consensus_data = await fetch(API + '/web/v1/index-prediction/actual_consensus?return_type=chart');
    let series = await consensus_data.json();
    this.setState({actual_consensus_data: series})
  };

  componentDidMount = async() => {
    await this.get_actual_consensus();
    this.set_index('hsi');
  };

  set_index = (_index) => {
    const {actual, pre, intra} = this.state.actual_consensus_data[_index];
    const the_series = [
      {
        data: [...actual],
        name: 'Actual<br/>Value',
        color: chart_actual_color,
        events: {
          legendItemClick: () => false
        }
      },
      {
        data: [...intra],
        name: 'Intra-day<br/>Consensus',
        color: chart_intra_color,
      },
      {
        data: [...pre],
        name: 'Pre-market<br/>Consensus',
        color: chart_pre_color,
        visible: false,
      },
    ];
    this.setState({indexSelected: _index, the_series: the_series});
  };

  render() {
    const optionsPredictionSite = {
      xAxis: {
        type: 'datetime',
        minTickInterval: 1000 * 60 * 60 * 24 * 30,
        labels: {
          format: '{value:%d/%m/%Y}'
        },
        visible: true,
        crosshair: {dashStyle: 'dash'}
      },
      yAxis: {
        offset: 50,
        labels: {
          format: '{value:,.0f}'
        },
        visible: true
      }
    };

    return (
      <section className='section section--grey-bg' id='criteria'>
        <div className="section__container container">
          <TabButton>
            <TabButtonItem
              className={classNames({active: this.state.indexSelected === 'hsi'})}
              tabId='HSI_chart' onClick={() => this.set_index('hsi')}>
              HSI
            </TabButtonItem>
            <TabButtonItem
              className={classNames({active: this.state.indexSelected === 'sp'})}
              tabId='SP_chart' onClick={() => this.set_index('sp')}>
              S&P
            </TabButtonItem>
          </TabButton>

          <br/>

          <GraphContainer type={'spline'} dataset={this.state.the_series} options={optionsPredictionSite}/>
        </div>
      </section>
    )
  }
}
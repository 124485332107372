import React from 'react';
import SourceIcon from '../../../assets/img/source.png';

const SourceButton = ({source, text}) =>
  source
  &&
  <div>
    <img alt={'SourceIcon'} src={SourceIcon} style={{marginRight: '5px', marginLeft: '0px'}}/>
    <a href={source} target="_blank" rel='noopener noreferrer' style={{
      fontSize: '16px',
      color: '#F05123',
      textDecoration: 'none'
    }}>
      Source{text}
    </a>
  </div>;

export default SourceButton;

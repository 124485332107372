import React from 'react';

const Dev = () =>
		<div className="section__container container">
			<h1>DEV PAGE</h1>
			<br/>
			
			<p>React version: {React.version}</p>
		</div>;

export default Dev;


import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import UserContext from "../../../Context";

const HamburgerSideDrawer = ({show, toggleShow}) => {
	const {userData} = useContext(UserContext);

	return (
		<nav>
			<ul>
				<div className={show ? 'side-drawer open' : 'side-drawer'}>
					{
						userData
						&&
						<li className={'navList'}>
							<Link to="/my-board" onClick={toggleShow}>
								<p>
									My Board
								</p>
							</Link>
						</li>
					}
					{
						[["/how-to-play", 'How To Play'], ["/leader-board", 'Leader board'],
							["/prediction-history", 'Prediction History']].map(link =>
							<li className={'navList'}>
								<Link to={link[0]} onClick={toggleShow}>
									<p>
										{link[1]}
									</p>
								</Link>
							</li>
						)
					}
				</div>
			</ul>
		</nav>
	);
};

export default HamburgerSideDrawer;

import React, {useEffect, useState} from 'react';
import {API} from '../../utilities/constantsFile';
import fetch from 'node-fetch';
import table_data_json from './table_data'
import Popup from "reactjs-popup";
import Button from 'react-bootstrap/Button';
import DayPicker from "react-day-picker";
import moment from "moment-timezone";
import HistoryTable from './HistoryTable'
import { Link } from 'react-router-dom';
import Spinner from '../component/Spinner';

const PredictionHistory = () => {
  const default_data = [{
    comment: '',
    date_time: '',
    exact_value: '',
    percentage: '',
    point: '',
    submit_date: '',
    timing: '',
    user_name: ''
  }];
  const [selectedDay, setSelectedDay] = useState(null);
  const [indexSelected, setIndexSelected] = useState('hsi');
  const [timingSelected, setTimingSelected] = useState('pre');
  const [consensus, setConsensus] = useState(0);
  const [currentGuest, setCurrentGuest] = useState(0);
  const [the_last_prediction, set_the_last_prediction] = useState([]);

  useEffect(() => {
      get_last_prediction(indexSelected, timingSelected).then();
  }, []);

  const get_last_prediction = async (_index, timing, start_date = null, end_date = null) => {
    let url;
    if (end_date && start_date)
      url = API + `/web/v1/index-prediction/last_prediction_v2?index=${_index}&start_date=${start_date}&end_date=${end_date}&timing=${timing}`;
    else
      url = API + `/web/v1/index-prediction/last_prediction_v2?index=${_index}&timing=${timing}`;
    let the_last_prediction = await fetch(url);
    the_last_prediction = await the_last_prediction.json();
    if (the_last_prediction.length !== 0) {
      set_the_last_prediction(the_last_prediction);
      setSelectedDay(moment(the_last_prediction[0].date_time.slice(0, 10)).format("YYYY-MM-DD"));
      setCurrentGuest(the_last_prediction.length)
    } else {
      setSelectedDay(moment(selectedDay).format('YYYY-MM-DD'));
      set_the_last_prediction(default_data);
      setCurrentGuest('N/A');
    }
  };

  const get_consensus = async (_index, timing, end_date = null) => {
    let url = API + `/web/v1/index-prediction/consensus/${_index}?timing=${timing}&end_date=${end_date}&start_date=${end_date}`;
    let consensus = await fetch(url);
    consensus = await consensus.json();
    if (consensus.length !== 0) {
      setConsensus(consensus[0].percentage)
    } else {
      setConsensus([])
    }
  };

  useEffect(() => {
    if (selectedDay && timingSelected && indexSelected) {
      const get_consensus_function_and_day_prediction = async () => {
        let day_for_api = moment(selectedDay).format('YYYY-MM-DD');
        await get_last_prediction(indexSelected, timingSelected, day_for_api, day_for_api);
        await get_consensus(indexSelected, timingSelected, day_for_api)
      };
      get_consensus_function_and_day_prediction().then();
    }
  }, [selectedDay, timingSelected, indexSelected]);

  const handleDayClick = (day, {selected}) => {
    setSelectedDay(selected ? undefined : day);
  };

  const set_index = async (_index) => {
    if (_index === 'sp') {
      setIndexSelected('sp');
    } else if (_index === 'hsi') {
      setIndexSelected('hsi');
    }
  };

  const change_timing = async (_timing) => {
    _timing === 'pre'
	    ? setTimingSelected('intra')
      : setTimingSelected('pre')
  };

  const p1 = () => {
    if (selectedDay) {
      return (
        <div className="headings_row">
          <h2 className={'mainHeading'}>
            {indexSelected === 'hsi' ? ' HSI ' : ' S&P '} Prediction
          </h2>
          <b className={'heading_objects'}
             onClick={() => set_index(indexSelected === 'hsi' ? 'sp' : 'hsi')}
          >
            Change to {indexSelected === 'hsi' ? ' S&P 500 ' : ' HSI '}
          </b>
          <div className={'calendar heading_objects'}>
            <Popup
              trigger={<Button
                className="dateButton"> {moment(the_last_prediction[0].date_time.slice(0, 10)).format("YYYY-MM-DD")} </Button>}
              closeOnDocumentClick
              contentStyle={{'width': '250px'}}>
              <DayPicker
                month={moment(selectedDay).toDate()}
                onDayClick={handleDayClick}
                selectedDays={moment(selectedDay).toDate()}
                disabledDays={[{daysOfWeek: [0, 6]}, day => day > (new Date())]}
              />
            </Popup>
          </div>
          <b className={'heading_objects'} style={{color: timingSelected === 'intra' && '#A1A1A1'}}
             onClick={() => change_timing('intra')}>
            Pre Market
          </b>
          <b className={'heading_objects'} style={{color: timingSelected === 'pre' && '#A1A1A1'}}
             onClick={() => change_timing('pre')}>
            Intra Market
          </b>
        </div>
      )
    }
  };

  const updateTableData = (data) => {
    data.forEach(datum => {
        datum['submit_date'] = datum['submit_date'].slice(11,);
        datum['user_name'] = <Link to = {{pathname: `/my-board/${datum['user_name']}`}}> {datum['user_name']} </Link>
      }
    );
    return data
  };

  if (the_last_prediction.length < 1)
    return <Spinner/>;
  return (
    <div className={'main_container'}>
      <div className={'background_pic'}>
        {p1()}
        <div className={'stats_container'}>
          <div className={'tab'}>
            <p className={'tab_heading'}>{currentGuest}</p>
            <h6>Current guest today</h6>
          </div>
          <div className={'tab'}>
            <p className={'tab_heading'}>{consensus.length !== 0 ? consensus + '%' : 'N/A'}</p>
            <h6>Current avg. consensus</h6>
          </div>
        </div>
        <div className={'table_container container'}>
          <br/>
          <HistoryTable data={updateTableData(the_last_prediction)} columns={table_data_json.col}/>
        </div>
      </div>
    </div>
  )
};

export default PredictionHistory;

import React, {Component} from 'react';
import {Button, Modal, ModalBody} from "reactstrap";
import {orange_color} from "../../utilities/constantsFile";
import add_comment from "../assets/img/add_comment.png";

export default class CommentModal extends Component {
    state = {
        commentHolder: null,
        modal: false,
    };

    toggle_modal = () => {
        this.setState({modal: !this.state.modal});
    };

    set_comment = () => {
        this.toggle_modal();
        this.props.set_comment_parent(this.state.commentHolder);
    };

    show_comment_modal = () => {
        return (
            <Modal className={'commentModel'} isOpen={this.state.modal} toggle={this.toggle_modal}>
                <ModalBody className={'commentModel'}>
                    {
                        this.props.comment
                        ?
                        <textarea placeholder={this.props.comment}  onBlur={(e) => this.setState({commentHolder: e.target.value})}/>
                        :
                        <textarea placeholder="Your comments here..."  onBlur={(e) => this.setState({commentHolder: e.target.value})}/>
                    }
                    <Button style={{background: orange_color, 'float': 'right'}} onClick={this.set_comment}>POST</Button>
                    <Button style={{'float': 'right', marginRight: '20px'}} color="secondary" onClick={this.toggle_modal}>Cancel</Button>
                </ModalBody>
            </Modal>
        )
    };

    render() {
        return (
            <>
                <img style={{margin: '10px'}} onClick={this.toggle_modal} src={add_comment} width="30" height="30" alt={"add_comment"}/>
                {this.show_comment_modal()}
            </>
        )
    }
}
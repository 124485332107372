import React from 'react';

const TabButtonItem = ({children, size, className, tabId, onClick}) => {
  const clickTab = (e) => {
    let activeId = e.target.id;
    let activeTab = activeId.slice(activeId.indexOf('_') + 1, activeId.length);
    onClick(activeTab);
  };
  let _style;
  switch (size) {
    case 'small':
      _style = {'height': '50px', 'width': '20%', 'fontSize': '13px', 'padding': '10px', 'textAlign': 'center'};
      break;
    case 'medium':
      _style = {'height': '50px', 'width': '35%', 'fontSize': '13px', 'padding': '10px', 'textAlign': 'center'};
      break;
    default:
      _style = {};
  }

  return (
    <p
      className={className === 'active'
        ? 'tab-item active'
        : 'tab-item'
      }
      id={'tabButton_' + tabId}
      style={_style}
      onClick={clickTab}
    >
      {children}
    </p>
  );
};

export default TabButtonItem;

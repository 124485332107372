import React, {useEffect, useState} from 'react';
import {BrowserRouter, Link, Route, Switch} from 'react-router-dom';
import './reactBasic/sass/main.scss';
import LandingPage from './views/LandingPage'
import HowToPlay from './views/HowToPlay'
import LeaderBoard from './views/Leaderboard'
import MyBoard from './views/MyBoard'
import predictionHistory from './views/PredictionHistory'
import NavBar from "./views/NavBar";
import Dev from "./views/Dev";
import Subscribe from "./reactBasic/components/basics/Subscribe";
import Footer from "./reactBasic/components/modules/Footer";
import './App.css';
import {getUser} from './utilities/auth';
import userContext from './Context';
import {main_site_url, my_site_url} from './utilities/constantsFile';
import UserBoard from "./views/user-board";

// use `location.pathname` if you want
const NoMatch = () =>
	<Link className={'minimal_center'} to="/">'
		<img alt={'404'} src="https://media3.giphy.com/media/14uQ3cOFteDaU/source.gif"
		     style={{width: '100%', height: 'auto'}}
		/>
	</Link>;

const App = () => {
	const [userData, setUserData] = useState();
	// userData.display_name or uuid
	
	useEffect(() => {
		(async () => {
			setUserData(await getUser());
		})();
	}, []);
	
	return (
		<userContext.Provider value={{userData: userData, setUserData: setUserData}}>
			<BrowserRouter>
				<NavBar/>
				<div>
					<Switch>
						<Route path="/" exact component={LandingPage}/>
						<Route path="/how-to-play" component={HowToPlay}/>
						<Route path="/leader-board" component={LeaderBoard}/>
						<Route path="/my-board/:id" render={({match}) => (<UserBoard id={match.params.id}/>)}/>
						<Route path="/my-board" component={MyBoard}/>
						<Route path="/prediction-history" component={predictionHistory}/>
						<Route path="/dev" component={Dev}/>
						<Route component={NoMatch}/>
					</Switch>
					<Subscribe/>
					<Footer mainSiteURL={main_site_url} predictionSiteURL={my_site_url}/>
				</div>
			</BrowserRouter>
		</userContext.Provider>
	)
};

export default App;

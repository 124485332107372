import React from 'react';

const UserContext = React.createContext();

// let initialState = {
// 	display_name: undefined,
// 	uuid: undefined
// };
//
// let reducer = (state, action) => {
// 	switch (action.type) {
// 		case "logout":
// 			return initialState;
// 		case "login":
// 			return { ...state, display_name: action.display_name, uuid: action.uuid};
// 		default:
// 			return {...state};
// 	}
// };
//
// const UserContextProvider = ({children}) => {
// 	let [userState, userDispatch] = React.useReducer(reducer, initialState);
// 	let value = { userState, userDispatch };
// 	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
// };


export default UserContext;

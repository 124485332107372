import React, {Component} from 'react';
import {API} from '../../../utilities/constantsFile'
import {TabButton, TabButtonItem} from '../../../reactBasic/components/basics/Tab';
import classNames from 'classnames';
import {MDBDataTable} from "mdbreact";
import Button from 'react-bootstrap/Button';
import {Card, CardBody, Row, Col} from 'reactstrap';
import DayPicker from 'react-day-picker';
import '../RoundWinner/calendar.css'
import '../RoundWinner/RoundWinner.css'
import Popup from "reactjs-popup";
import moment from 'moment-timezone'
import daily_winner_table_data from './daily_winner_table_data'
import {Link} from "react-router-dom";

const updateTableData = (data) => {
	data.forEach(datum =>
		datum['user_name'] = <Link to={{pathname: `/my-board/${datum['user_name']}`}}> {datum['user_name']} </Link>
	);
	return data
};

const format_table_data = (data, game_type) =>
	data.reduce((acc, cur, i) =>
			acc.concat({"rank": i + 1, "user_name": cur.user_name, [game_type]: cur[game_type]})
		, []);

export default class DailyWinner extends Component {
	state = {
		index_type: 'hsi',
		data: {
			"date": null,
			"hsi": {"pre": {"diff": [], "score": []}, "intra": {"diff": [], "score": []}},
			"sp": {"pre": {"diff": [], "score": []}, "intra": {"diff": [], "score": []}},
		},
		gaming_type: 'diff',
		selectedDay: null,
		latest_date: null
	};
	
	componentDidMount = async () => {
		let url = API + `/web/v1/index-prediction/daily_winner`;
		let res = await fetch(url);
		res = await res.json();
		let latest_date = await moment(res.date).format('YYYY-MM-DD');
		await this.setState({
			selectedDay: latest_date,
			latest_date: latest_date,
			data: res
		})
	};
	
	get_daily_winner = async (date) => {
		let url = API + `/web/v1/index-prediction/daily_winner/${date}`;
		let res = await fetch(url);
		res = await res.json();
		this.setState({
			data: res
		});
		return res
	};
	
	async change_day(operation) {
		let {selectedDay, index_type, gaming_type} = this.state;
		if (operation === 'add') {
			let day = 1;
			for (let i = day; ; i++) {
				let data_for_date = await this.get_daily_winner(moment(selectedDay).add(i, 'days').format('YYYY-MM-DD'));
				if (data_for_date[index_type].pre[gaming_type].length ||
					data_for_date[index_type].intra[gaming_type].length) {
					day = i;
					break;
				}
			}
			let new_date = moment(this.state.selectedDay).add(day, 'days').format('YYYY-MM-DD');
			this.setState({selectedDay: new_date});
			this.get_daily_winner(new_date);
		} else if (operation === 'subtract') {
			let day = 1;
			for (let i = day; ; i++) {
				let data_for_date = await this.get_daily_winner(moment(selectedDay).subtract(i, 'days').format('YYYY-MM-DD'));
				if (data_for_date[index_type].pre[gaming_type].length ||
					data_for_date[index_type].intra[gaming_type].length) {
					day = i;
					break;
				}
			}
			let new_date = moment(this.state.selectedDay).subtract(day, 'days').format('YYYY-MM-DD');
			this.setState({selectedDay: new_date});
			this.get_daily_winner(new_date);
		}
	};
	
	handleDayClick = (day, {selected}) => {
		this.setState({
			selectedDay: selected ? undefined : day,
		}, async () => {
			let day_for_api = moment(this.state.selectedDay).format('YYYY-MM-DD');
			await this.get_daily_winner(day_for_api);
		});
	};
	
	toggle_gaming_type = () => {
		if (this.state.gaming_type === 'diff') {
			this.setState({gaming_type: 'score'});
		} else if (this.state.gaming_type === 'score') {
			this.setState({gaming_type: 'diff'});
		}
	};
	
	render() {
		let {gaming_type, selectedDay, latest_date, index_type, data} = this.state;
		
		if (selectedDay === null || data.date === null)
			return null;
		
		let table_col, left_table_data, right_table_data;
		
		table_col = daily_winner_table_data.diff_col_name;
		left_table_data = format_table_data(data[index_type].pre[gaming_type], gaming_type);
		right_table_data = format_table_data(data[index_type].intra[gaming_type], gaming_type);
		
		return (
			<div className={'section__container container'}>
				<h1 style={{textAlign: 'center'}}>Daily Winner</h1>
				<TabButton>
					<TabButtonItem
						className={classNames({active: index_type === 'hsi'})}
						onClick={() => this.setState({index_type: 'hsi'})}>
						HSI
					</TabButtonItem>
					<TabButtonItem
						className={classNames({active: index_type === 'sp'})}
						onClick={() => this.setState({index_type: 'sp'})}>
						S&P
					</TabButtonItem>
				</TabButton>
				<br/>
				<br/>
				<Card>
					<CardBody>
						<Row style={{'borderBottom': 'solid 1px #DDD', 'marginLeft': '5%'}}>
							<Col xs='4' sm='4' lg='4'>
								<b
									style={{'fontSize': '14px', 'color': '#F05123'}}
									onClick={this.toggle_gaming_type}
								>Switch to {gaming_type === 'diff' ? "Diff" : "Score"} Game</b>
							</Col>
							<Col xs='6' sm='6' lg='6' style={{textAlign: 'right', 'marginBottom': '20px'}}>
								<Button onClick={() => {
									this.change_day('subtract')
								}}>
									&#8678;
								</Button>
								<Popup
									trigger={<Button
										className="dateButton btn-dark"> {moment(selectedDay).format("YYYY-MM-DD")} </Button>}
									closeOnDocumentClick
									contentStyle={{'width': '250px'}}>
									<DayPicker
										month={new Date(selectedDay)}
										onDayClick={this.handleDayClick}
										disabledDays={[{daysOfWeek: [0, 6]}, day => day > (new Date())]}
									/>
								</Popup>
								{
									moment(selectedDay).add(1, 'days') > moment(latest_date)
										? <Button disabled> &#8680; </Button>
										: <Button onClick={() => {
											this.change_day('add')
										}}
										> &#8680; </Button>
								}
							</Col>
						</Row>
						
						<Row className={'UglyLeaderboardRow'}>
							<Col>
								<h3 style={{textAlign: 'center', 'fontWeight': '700'}}>Pre Market</h3>
								<MDBDataTable
									searching={false}
									displayEntries={false}
									className={'MDBDataTable'}
									autoWidth={true}
									responsive={true}
									entries={5}
									paginationLabel={["<", ">"]}
									data={{columns: table_col, rows: updateTableData(left_table_data)}}
								/>
							</Col>
							<Col>
								<h3 style={{textAlign: 'center', 'fontWeight': '700'}}>Intra Market</h3>
								<MDBDataTable
									searching={false}
									displayEntries={false}
									className={'MDBDataTable'}
									autoWidth={true}
									responsive={true}
									entries={5}
									paginationLabel={["<", ">"]}
									data={{columns: table_col, rows: updateTableData(right_table_data)}}/>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</div>
		)
	}
}

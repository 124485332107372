import React from 'react';
import Button from '../../../../components/basics/Button';

const ProductCard = ({icon, title, content, ButtonText}) =>
  <div style={{display: 'flex', flexDirection: 'row', margin: '20px'}}>
    <div className="icon" style={{alignSelf: 'center'}}>
      <img src={icon} alt={'ProductCard' + icon} width="50px" height="50px" style={{alignSelf: 'center'}}/>
    </div>
    <div className="text" style={{
      margin: '20px',
      display: 'flex',
      flexDirection: 'column',
      marginBlockStart: '3%',
      marginBlockEnd: '3%',
      textAlign: 'left'
    }}>
      <p><strong>{title}</strong></p>
      <p>{content}</p>
      <Button text={ButtonText} size={'lg'} color={'navy'} outline/>
    </div>
  </div>;

export default ProductCard;

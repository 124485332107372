import React, {Component} from 'react';
import Highcharts from 'highcharts';
import LineGraph from './LineGraph';
import {graph_color, theme_orange} from '../highchart/graphConstant';
import '../highchart/GraphContainer.scss';

export default class GraphContainer extends Component {
  state = {
    type: this.props.type
  };

  render() {
    const {type} = this.state;
    const {dataset, options, button, marker} = this.props;
    const graphOptions = {
      credits: {
        text: ''
      },

      title: {
        visible: false
      },

      scrollbar: {
        enabled: false
      },

      navigator: {
        enabled: true
      },

      chart: {
        xAxis: {
          type: 'datetime'
        },
        backgroundColor: graph_color,
        height: 600,
        spacing: [30, 30, 30, 30]
      },

      legend: {
        enabled: true,
        align: 'center',
        verticalAlign: 'bottom',
        padding: 20,
        itemStyle: {
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }
      },

      rangeSelector: {
        inputEnabled: false,
        selected: 1,
        labelStyle: {
          display: 'none'
        },
        buttons: button ? button : [{
          type: 'month',
          count: 1,
          text: '1M'
        }, {
          type: 'month',
          count: 3,
          text: '3M'
        }, {
          type: 'year',
          count: 1,
          text: '1Y'
        }, {
          type: 'year',
          count: 5,
          text: '5Y'
        }, {
          type: 'all',
          text: 'MAX'
        }],
        buttonSpacing: -1,
        buttonTheme: {
          visibility: 'show',
          width: 45,
          padding: 13,
          stroke: '#A4A4A4',
          'stroke-width': 1,
          fill: 'none',
          style: {
            color: 'black',
            fontWeight: 'bold'
          },
          states: {
            hover: {
              fill: 'none',
              style: {
                color: theme_orange
              }
            },
            select: {
              fill: theme_orange,
              style: {
                color: 'white'
              }
            }
          }
        }
      },

      tooltip: {
        enabled: true,
        shared: true,
        backgroundColor: 'rgba(0,0,0,0.85)',
        borderWidth: 0,
        borderRadius: 10,
        shadow: false,
        useHTML: true,
        formatter: function () {
          const container = this.points.reduce(function (s, point) {
            return s + `<p style="color:${point.series.color}; margin:10px 0">${point.series.name}<br/>`
              + `<span style="color:white">${point.y.toFixed(2)}</span></p>`;
          }, `<p style="color:${theme_orange}; padding-bottom:5px; border-bottom:1px solid grey; margin:0">${Highcharts.dateFormat('%d/%m/%Y', new Date(this.x))}</p>`);
          return `<div style="text-align:center">${container}</div>`;
        }
      },

      responsive: {
        rules: [{
          condition: {
            maxWidth: 425
          },
          chartOptions: {
            rangeSelector: {
              enabled: false
            }
          }
        }]
      }
    };

    for (const property in options) {
      if (options.hasOwnProperty(property)) {
        graphOptions[property] = options[property];
      }
    }
    graphOptions.series = dataset;
    graphOptions.series.forEach(item => {
      item.enableMouseTracking = true;
      item.type = type;
      item.lineWidth = 3;
      !marker && (item.marker = {symbol: 'circle', lineColor: null, lineWidth: 5});
      item.showInNavigator = true;
    });

    return (
      <div style={{backgroundColor: 'white'}}>
        <LineGraph config={graphOptions}/>
      </div>
    );
  }
}

import React from 'react';
import ReactTable from "react-table";
import './PredictionHistory.css';
import 'react-table/react-table.css'

const HistoryTable = ({data, columns, pageSize, showPagination=true, style}) =>
	<ReactTable
      data={data}
      columns={columns}
      defaultPageSize={10}
      minRows={1}
			showPagination={showPagination}
			style = {style}
      // pivotBy={['submit_date']}
	/>;

export default HistoryTable;
import React, {Component} from 'react';
import './Leaderboard.css'
import { bg_light_grey} from '../../utilities/constantsFile';
import ChampionUpper from './ChampionUpper'
import RoundWinner from "./RoundWinner";
import DailyWinner from "./DailyWinner/DailyWinner";

export default class Leaderboard extends Component {
    render() {
        return (
            <div style={{backgroundColor: bg_light_grey}}>
                <ChampionUpper/>
                <br/>
                <RoundWinner/>
                <br/>
                <DailyWinner/>
            </div>
        )
    }
}

export const bg_color = '#ffffff';
export const bg_light_grey = '#F7F7F7';
export const chart_actual_color = '#f5a623';
// export const chart_guess_color = '#153a75';
export const chart_intra_color = '#5995ef';
export const chart_pre_color = '#7da800';
export const chart_user_name_color = '#a84e6e';

export const green_color = '#00ff00';
export const red_color = '#ff0000';
export const orange_color = '#ff5524';

// TODO: change API when deploy
// export const API = 'http://localhost:3004';

export const is_prod = process.env.ENVIRONMENT === 'production';
export const main_site_url = 'https://www.invbots.' + (is_prod ? 'com' : 'dev');
export const my_site_url = 'https://prediction.invbots.' + (is_prod ? 'com' : 'dev');

export const API = 'https://api.invbots.com';
export const API_data = 'https://api.invbots.com/data/v1';
